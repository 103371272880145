import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import JwtSnippet from '../../../snippets/jwt-payloads/age-verification';
import AuthorizeURLBuilder from '../../../components/AuthorizeURLBuilder';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 0,
  "title": "Age Verification",
  "subtitle": "How to leverage Criipto Verify's Age Verification extension"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With the Age Verification extension, you can verify user ages without collecting personal data.
The authentication result will simply confirm if the user meets the age requirement, without including sensitive information such as their full name or birthdate.`}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`Age Verification does not require you to set up your own eID provider, as it defaults to using shared Criipto-provided eID providers. This batteries included approach comes with certain limitations, such as the absence of many tenant-specific features.`}</p>
      <p>{`For instance, the `}<a parentName="p" {...{
          "href": "https://docs.criipto.com/verify/guides/custom-styling/"
        }}>{`Custom styling feature`}</a>{` is not available out of the box for Age Verification applications. To enable it, you can switch to using `}<a parentName="p" {...{
          "href": "https://docs.criipto.com/verify/guides/age-verification/#optional-use-your-own-mitid-service-provider"
        }}>{`your own MitID provider`}</a>{`.`}</p>
    </Highlight>
    <h2>{`Prerequisites`}</h2>
    <p>{`You'll need a `}<a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/getting-started/basics/#tenants"
      }}>{`Criipto tenant`}</a>{` and `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/domains"
      }}>{`domain`}</a>{` to get started.`}</p>
    <h2>{`Create Age Verification application`}</h2>
    <ol>
      <li parentName="ol">{`On your dashboard, navigate to the `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/applications"
        }}><strong parentName="a">{`Applications`}</strong></a>{` tab and click `}<strong parentName="li">{`Add application`}</strong>{`.`}</li>
      <li parentName="ol">{`When prompted to choose a product, select `}<strong parentName="li">{`Age Verification`}</strong>{`, then click `}<strong parentName="li">{`Create`}</strong>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.416666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABiElEQVQoz52SyY7UQAyG8/7izvMgISFOXDjO0E1CupPeslSqUvs3qtR0msAgEJZ+WWWX7d9LIYSkba+czjea9so0KZLEGP8ZSUKMeB8opJyXRE174XS6obX5KSG8/v+r3JMXSmm6fqDvR7p+3Dgh40+skg7hgWQuknESEedypceHgJ4DSobXwKxTW0oFxiH7rI1Yk6HnmBPunx3T+Ki6aAJjHzhUYdNaKpYK7Z48YtjOw5hIkT4oZRHCoJQjBFZGWvvFnjBNZm1R64CUlnHMPucy+zVh2zj23wzVd0O5t8sIEseh9+yeDOXOcLvmmXifGf4oLdXeLHF16dGapf2l5abpqOsLx6bjeLwhVWYjppmyOlNV58WfLiJNRM+e+nClPlxoTx1N22Gdw1oyQyEUadv3VtPgkzbGMgwTwyhXfWeYYqZJL29jPNaGx1J+vaX7rOIbR5jszka8g+DB2a2/2N7db+f65v2tW7WOGB3V3vHpg6Y9+Mzwf5CkHySzlnz+qHn/TvD1i+UFDUSqkqP4jPEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Create Age Verification application",
          "title": "Create Age Verification application",
          "src": "/static/4285c28e74b84ddae5d84f97d93913b6/e5715/age-verification-create.png",
          "srcSet": ["/static/4285c28e74b84ddae5d84f97d93913b6/8514f/age-verification-create.png 192w", "/static/4285c28e74b84ddae5d84f97d93913b6/804b2/age-verification-create.png 384w", "/static/4285c28e74b84ddae5d84f97d93913b6/e5715/age-verification-create.png 768w", "/static/4285c28e74b84ddae5d84f97d93913b6/4ad3a/age-verification-create.png 1152w", "/static/4285c28e74b84ddae5d84f97d93913b6/236d7/age-verification-create.png 1486w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Fill in the details to create your application, then click `}<strong parentName="li">{`Create application`}</strong>{`.`}</li>
      <li parentName="ol">{`You're all set: the Age Verification feature will be automatically enabled after creating the application.`}</li>
    </ol>
    <Highlight icon="info" mdxType="Highlight">
      <p><em parentName="p">{`If the Age Verification application type is not available, or you're running into any issues during application creation, please contact us via `}<a parentName="em" {...{
            "href": "https://tiny.cc/criipto-slack"
          }}>{`Slack`}</a>{` or by `}<a parentName="em" {...{
            "href": "mailto:support@criipto.com"
          }}>{`email`}</a>{`.`}</em></p>
    </Highlight>
    <h2>{`Implementation`}</h2>
    <h3>{`Triggering Age Verification`}</h3>
    <p>{`Age verification is triggered via a `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/"
      }}>{`standard OpenID Connect integration`}</a>{` using the following settings:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`acr_values=urn:age-verification`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`login_hint=country:{country}`}</inlineCode>{`, `}<inlineCode parentName="li">{`{country}`}</inlineCode>{` can be one of `}<inlineCode parentName="li">{`DK`}</inlineCode>{`|`}<inlineCode parentName="li">{`SE`}</inlineCode>{`|`}<inlineCode parentName="li">{`NO`}</inlineCode>{`|`}<inlineCode parentName="li">{`FI`}</inlineCode>{`. `}<em parentName="li">{`This determines the `}<a parentName="em" {...{
            "href": "/verify/e-ids/"
          }}>{`eID provider`}</a>{` for user authentication`}</em>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scope=openid is_over_18`}</inlineCode>{`; Other supported values are: `}<inlineCode parentName="li">{`is_over_15`}</inlineCode>{`, `}<inlineCode parentName="li">{`is_over_16`}</inlineCode>{`, `}<inlineCode parentName="li">{`is_over_21`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Example JWT`}</h3>
    <p>{`The resulting JWT will indicate whether the user meets the age requirement. Personal data, such as the user's full name and birthdate, is not included.`}</p>

    <JwtSnippet mdxType="JwtSnippet" />
    <Highlight icon="info" mdxType="Highlight">
      <p>{`The `}<inlineCode parentName="p">{`sub`}</inlineCode>{` field is random for each login and cannot be used to correlate the user.`}</p>
    </Highlight>
    <h2>{`Authorize URL Builder`}</h2>

    <AuthorizeURLBuilder acr_values={["urn:age-verification"]} login_hint="country:DK" scope={['openid', 'is_over_18']} quirks={false} mdxType="AuthorizeURLBuilder" />
    <h2>{`Optional: use your own MitID service provider`}</h2>
    <p>{`If you prefer to use your own MitID service provider instead of the one provided by Criipto, you have the option to do so. `}</p>
    <p><a parentName="p" {...{
        "href": "/verify/guides/age-verification/#installation"
      }}>{`During installation`}</a>{`, flip the `}<strong parentName="p">{`Use own MitID`}</strong>{` setting to enabled, and then ensure that your Criipto application has allowed `}<inlineCode parentName="p">{`https://{YOUR_CRIIPTO_DOMAIN}/extensions/ext_ageverification/api/callback`}</inlineCode>{` as a callback URL.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.45833333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAA+UlEQVQoz3WRzW6DMBCEef9jr32PXvoSCSIhbQRE4tfgJCym2GCUqWygSio4fBp7PF7t2k5VEb7PMcIosyqEwjgCw/CA1g+rC3r2tjAZp+tGEA1oGr2KEBqinWiE3sxRo2FqOUQa3pHhcCyQpB3SrLNqyHKJIBA4eATfJ0SXn5fzBePFSYd7PUwFl8vPmFBeKAQhYe8y7N0C/okjuhDCqJ6hGbNuUNMAx4zESoWqUig3kRZWyhe/4j0qrnC9K9xqZZ/EMVXjZH2U55EWpr1EnArsdjd8nSQ+PwTe3+qpw7bVtjt+7cH5P9a8P18hzyUY6xGFCp4r7cf9As/cEaVa3Js7AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Extensions Catalogue",
          "title": "Extensions Catalogue",
          "src": "/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/e5715/age-verification-own-MitID.png",
          "srcSet": ["/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/8514f/age-verification-own-MitID.png 192w", "/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/804b2/age-verification-own-MitID.png 384w", "/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/e5715/age-verification-own-MitID.png 768w", "/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/4ad3a/age-verification-own-MitID.png 1152w", "/static/67b1dee4204f9fc9ff6fcb1799e1b0cd/45662/age-verification-own-MitID.png 1410w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Note: You need to `}<a parentName="em" {...{
          "href": "/verify/e-ids/danish-mitid/#apply-for-production-access"
        }}>{`apply for MitID production access`}</a>{` for Age Verification logins `}<strong parentName="em">{`only`}</strong>{` if using your own MitID service provider.`}</em></p>
    <h2>{`Auditing`}</h2>
    <h3>{`Denmark`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` is issued in such a way that you can safely store it without worrying about personal data.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` contains a reference to a Criipto managed audit log that contains the users MitID `}<inlineCode parentName="p">{`uuid`}</inlineCode>{`,
upon request an `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` can be presented to Criipto and we can help prove the identity (and thus age) of the user.`}</p>
    <p>{`For e-commerce scenarios we suggest storing the `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` together with the order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      